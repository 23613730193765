
onReady(() => {



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * navButton
    // *
    // *
    $(".js-nav-button").on("click", function () {
        $("body").toggleClass("freeze");
        $(".js-nav-main").toggleClass("active");
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * js-slide-toggle / accordion
    // *
    // *
    document.querySelectorAll(".js-slide-source").forEach(element => {

        element.addEventListener("click", function () {
            const parent = element.closest(".js-slide-parent");
            const siblings = getAllSiblings(parent);
            const slideElem = parent.querySelector(".js-slide-target");

            if (element.closest(".js-accordion")) {
                siblings.forEach(parent => {
                    parent.classList.remove("active");
                    const slideElem = parent.querySelector(".js-slide-target");
                    slideUp(slideElem);
                })
            }


            parent.classList.toggle("active");
            slideToggle(slideElem);

            // for mobile slide to parent
            if (parent.classList.contains("active")) {
                setTimeout(() => {
                    element.scrollIntoView();
                }, 350);
            }

        });
    });

    // Slide first accordion down on mobile
    // if (window.innerWidth < 800) {
    //     const firstParent = document.querySelector(".js-slide-parent");
    //     firstParent.classList.add("active")
    //     slideToggle(firstParent.querySelector(".js-slide-target"));

    // }

    if (window.location.hash) {
        setTimeout(function () {
            $(`${window.location.hash}`).click();
        }, 1000);
    }

    $("a").click(function () {
        setTimeout(function () {
            $(`${window.location.hash}`).click();
        }, 1000);

    });



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * parallax
    // *
    // *
    // Register the ScrollTrigger plugin
    gsap.registerPlugin(ScrollTrigger);

    // Get the layers and set initial position
    document.querySelectorAll(".js-parallax-container").forEach((container) => {
        const layers = container.querySelectorAll(".js-parallax-layer");

        layers.forEach((layer) => {
            gsap.from(layer, {
                yPercent: () => 50 * layer.dataset.depth,
                ease: "none",
                scrollTrigger: {
                    trigger: container,
                    scrub: true,
                    start: "center bottom",
                    end: "center top",
                    // markers: true
                }
            });
        });
    })

    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * masonry
    // *
    // *
    var elem = document.querySelector('.js-masonry');
    if (elem) {
        var msnry = new Masonry(elem, {
            // options
            itemSelector: '.js-masonry-item',
        });
    }



    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * sliderGallery
    // *
    // *
    const sliderGallery = document.querySelectorAll(".js-slidergallery-wrapper");

    sliderGallery.forEach((element) => {

        const containerMaxWidth = 1616;
        const containerMaxMargin = 152;
        const containerMinMargin = 35;
        // see clamp in globals.scss
        const containerMarginGradient = 5.2 + 7.6451 * window.innerWidth / 100;
        const containerMargin = Math.min(Math.max(containerMarginGradient, containerMinMargin), containerMaxMargin)

        const sliderWidth = element.scrollWidth;
        let scrollWidth = 0;
        if (window.innerWidth < 1920) {
            scrollWidth = sliderWidth - window.innerWidth + containerMargin
        }
        else {
            scrollWidth = sliderWidth - containerMaxWidth - containerMargin;
        }
        // cap to 0
        scrollWidth = scrollWidth < 0 ? 0 : scrollWidth;

        gsap.to(element, {
            x: -scrollWidth,
            ease: "none",
            scrollTrigger: {
                trigger: element,
                scrub: true,
                pin: true,
                markers: false,
                start: "top+=5% center-=30%",
                end: "bottom+=1500 center+=30%"
            }
        })
    });




    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * anchor fix chrome/safari
    // *
    // *
    var isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (window.location.hash && isSafari || isChrome) {

        setTimeout(function () {
            var hash = window.location.hash;
            window.location.hash = "";
            window.location.hash = hash;
        }, 300);
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * load more
    // *
    // *
    var $wrapper = $(".js-loadmore-wrapper"),
        $pagination = $(".js-loadmore-pagination"),
        $button = $(".js-loadmore-button");

    $button.click(function () {
        var url = $pagination.find("a.next").attr("href");
        var $nextLink = $pagination.find("a.next").removeClass("next").next("a").addClass("next");

        if (!$pagination.find("a.next").length) {
            $button.fadeOut();
        }

        $.get(url).done(function (data) {
            $(data).find(".js-loadmore-wrapper .js-loadmore-item").appendTo($wrapper);
        });
    });

    // mark next navigation page for load more button on init
    $pagination.find("a.active").next("a").addClass("next");

    // hide button if not enough news on init
    if ($pagination.find("a").length <= 1) {
        $button.hide();
    }


    // * * * * * * * * * * * * * * * * * * * * * * * * *
    // * above the fold loading eager
    // *
    // *

    var lazyImages = document.querySelectorAll('img');

    function applyEagerLoading(entries, observer) {
        entries.forEach(function (entry) {
            if (entry.isIntersecting) {
                entry.target.setAttribute('loading', 'eager');
                observer.unobserve(entry.target);
            }
        });
    }

    var observer = new IntersectionObserver(applyEagerLoading, { threshold: 0 });

    lazyImages.forEach(function (img) {
        observer.observe(img);
    });


    // * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
    // * dialog window
    // *
    // *
    // news
    var newsDialog = document.querySelector(".js-news");

    var newsState = sessionStorage.getItem('newsState');

    var closeNewsButton = document.querySelector(".js-close-news");

    if (closeNewsButton) {
        closeNewsButton.addEventListener("click", function () {
            newsDialog.classList.add("close");

            sessionStorage.setItem('newsState', true);

            if (newsDialog) {
                newsDialog.close();
            }

            document.body.classList.remove("freeze");
        });
    }

    if (!newsState && newsDialog) {
        newsDialog.showModal();
        document.body.classList.add("freeze");
    }


});

