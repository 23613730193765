const onReady = (callback) => {
    if (document.readyState != 'loading') callback();
    else if (document.addEventListener) document.addEventListener('DOMContentLoaded', callback);
    else document.attachEvent('onreadystatechange', function () {
        if (document.readyState == 'complete') callback();
    });
};

// const $ = document.querySelector.bind(document);
// const $$ = document.querySelectorAll.bind(document);

function slideToggle(element) {
    element.style.height = element.style.height === '' ? `${element.scrollHeight}px` : '';
};

function slideUp(element) {
    element.style.height = '';
};

function hide(element) {
    element.style.display = 'none';
};

function getAllSiblings(element) {
    const siblings = [];
    let sibling = element.parentNode.firstChild;
  
    while (sibling) {
      if (sibling !== element && sibling.nodeType === 1) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling;
    }
  
    return siblings;
  }
  